<template>
  <div class="workload-chart" style="margin: 0rem;" v-loading="loading">
    <div class="workload-chart__filterbar">
      <el-date-picker
        v-model="dateRange"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="-"
        :start-placeholder="format(new Date(form.start), 'yyyy-MM-dd')"
        :end-placeholder="format(new Date(form.end), 'yyyy-MM-dd')"
        @change="onChangeDateRange"
      ></el-date-picker>

      <el-radio-group v-model="form.mode">
        <el-radio-button label="0">Monat</el-radio-button>
        <el-radio-button label="1">Tag</el-radio-button>
      </el-radio-group>

      <el-button type="primary" plain @click.prevent="requestChartData">Hole Statistic</el-button>
    </div>

    <!-- Chart -->
    <div class="workload-chart__chart-sum-container">
      <div class="workload-chart__chart-container">
        <LineChart :chart-data="chartData" :options="chartOptions" :loaded="dataLoaded" :width="400" :height="400" v-if="dataLoaded" />
      </div>

      <!-- Total -->
      <div class="workload-chart__sum">
        <div class="workload-chart__sum-item">
          <div class="color-gray">Gesamt</div>
          <div class="m-t-half color-brand" style="font-size: 2.5rem;">{{ sumHours }}</div>
          <div class="m-t-half color-gray">Stunden</div>
        </div>
        <div class="workload-chart__sum-item">
          <div class="color-gray">Durchschnitt</div>
          <div class="m-t-half color-brand" style="font-size: 2.5rem;">{{ avg_hours }}</div>
          <div class="m-t-half color-gray">Stunden</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endOfYear, format, startOfYear } from 'date-fns';
import LineChart from '@/components/Chart/LineChart';
import * as TimetrackerService from '@/services/timetracker';

export default {
  name: 'ChartWorkload',

  components: {
    LineChart
  },

  data() {
    return {
      loading: false,
      avg_hours: 0,
      sumHours: 0,
      total: 0,
      form: {
        start: format(startOfYear(new Date()), 'yyyy-MM-dd HH:mm'),
        end: format(endOfYear(new Date()), 'yyyy-MM-dd HH:mm'),
        mode: 0
      },
      dateRange: [],
      dataLoaded: true,
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Arbeitsstunden',
            data: [],
            fill: 'start',
            backgroundColor: 'rgba(52, 152, 219,0.2)',
            borderColor: 'rgba(52, 152, 219,1)',
            borderWidth: 3
          },
          {
            label: 'bezahlte Arbeitsstunden',
            data: [],
            fill: 'start',
            backgroundColor: 'rgba(63, 195, 128, 0.2)',
            borderColor: 'rgba(63, 195, 128, 1)',
            borderWidth: 3
          },
          {
            label: 'Planzahl',
            data: [],
            fill: 'start',
            backgroundColor: 'rgba(253, 227, 167, 0.4)',
            borderColor: 'rgba(253, 227, 167, 1)',
            borderWidth: 3
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              categorySpacing: 0,
              barPercentage: 0.7
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    };
  },

  mounted() {
    this.init();

    this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case 'tracking/SET_TIMETRACKER':
          this.requestChartData();
          break;
      }
    });
  },

  methods: {
    format,

    init() {
      this.requestChartData();
    },

    onChangeDateRange(value) {
      this.form.start = format(value[0], 'yyyy-MM-dd HH:mm');
      this.form.end = format(value[1], 'yyyy-MM-dd HH:mm');
    },

    requestChartData() {
      this.loading = true;
      this.dataLoaded = false;

      TimetrackerService.getWorkloadChartPerDay(this.form)
        .then(response => {
          this.loading = false;
          const { data } = response;
          if (!data) {
            return;
          }

          if (data) {
            if (data.labels) {
              this.chartData.labels = data.labels;
            }
            if (data.datasets) {
              this.chartData.datasets[0].data = data.datasets[0].data; // total
              this.chartData.datasets[1].data = data.datasets[1].data; // paid

              if (data.datasets[2] && data.datasets[2].hasOwnProperty('data')) {
                this.chartData.datasets[2].data = data.datasets[2].data; // target figures
              }

              this.sumHours = 0;
              data.datasets[0].data.forEach(hours => {
                this.sumHours += hours;
              });

              if (this.sumHours > 0 && data.datasets[0].data.length) {
                this.sumHours = Number(this.sumHours).toFixed(1);
                this.avg_hours = Number(this.sumHours / data.datasets[0].data.length).toFixed(1);
              }
            }
            this.dataLoaded = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.dataLoaded = true;
        });
    }
  }
};
</script>

<style lang="scss">
.workload-chart__filterbar {
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-bottom: 0.625rem;
    &:not(:last-child) {
      margin-right: 0.625rem;
    }
  }
}

.workload-chart__chart-sum-container {
  margin-top: 1rem;

  @media screen and (min-width: $screen-md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.workload-chart__chart-container {
  height: 400px;
  width: 100%;

  > * {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  @media screen and (min-width: $screen-md), (max-width: $screen-lg) {
    width: calc((100% / 3) * 2);
  }

  @media screen and (min-width: $container-width-xl) {
    width: calc((100% / 3) * 2);
  }

  @media screen and (max-width: $screen-md) {
    width: 100%;
  }
}

.workload-chart__sum {
  margin-top: 2rem;

  @media screen and (min-width: $screen-md), (max-width: $screen-lg) {
    margin-top: 0;
    width: calc((100% / 3) * 1);
  }

  @media screen and (min-width: $container-width-xl) {
    margin-top: 0;
    width: calc((100% / 3) * 1);
  }

  @media screen and (max-width: $screen-md) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

.workload-chart__sum-item {
  text-align: center;
  background-color: $c-lighter-gray;
  padding: 2rem;

  flex: 1;
  margin: 0.625rem;
  width: 100%;

  @media screen and (min-width: $screen-md) {
    padding: 1rem;
  }

  @media screen and (min-width: $screen-lg) {
    padding: 2rem;
  }
}
</style>
